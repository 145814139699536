<script setup>
import { defineProps } from 'vue';
const props = defineProps({
    indexof: Boolean
})

function returnindex() {
    window.location.href = '/'
}
</script>

<template>
<div class="sticky">
    <div class="nav">
        <!-- 返回首页键 -->
        <div v-if="indexof" class="return" @click="returnindex()"><</div>
        <div class="nav_logo" :style="indexof ?'margin-right: 1.74em;' :''">
            <img src="../assets/logo.png" alt="">
        </div>
    </div>
</div>
</template>

<style>
*{
    margin: 0;
    padding: 0;
}
.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    height: 5em;
}
.nav {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    top: 0;
    width: 100%;
    height: 3em;
}
.return{
    font-size: 2em;
    /* 靠右 */
    position: relative;
    right: calc( 49% - 2em / 137 * 253);/* 253/137 */
    border-radius: 10px;
    padding: 0.5em 1em;
}
.return:hover{
    background-color: #f0f0f0;
    cursor: pointer;
}
.nav_logo {
    height: 4em;
    font-size: 2.5em;
    font-weight: bold;
    color: #000000;
    text-align: center;
    line-height: 4em;
    /*楷体*/
    font-family: "KaiTi";
    font-weight: bold;
}
.nav_logo img {
    height: 1.5em;
    vertical-align: middle;
}
</style>